<template>
	<!-- <div class="title" :class="{bgVip: hasCode !== 1}"> -->
	<div class="title">
		<!-- <div v-if="hasCode == 1"> -->
		<div>
			<common-header bg_size="100% calc(env(safe-area-inset-top) + 5.59rem)" :bg_src="require('../../assets/imgs/new_color/yaoqing_bg.png')" :title="$t('system.invitefriends')" :is-return="true" style="padding-top: env(safe-area-inset-top);"></common-header>
			<div class="layoutBox">
				<!-- <div class="shareAds">
					<div class="big_txt">{{ $t('system.yao_dejiangli') }}</div>
					<div class="small_txt">{{ $t('system.yaozu_dejiangli') }}</div>
				</div> -->
				<div class="shareInfo">
					<div class="top">
						<div class="item">
							<div class="item_left">
								{{ $t('system.Myinvitationcode') }}
								<div class="inp">{{ inviteCode }}</div>
							</div>
							<van-button class="copy copyCode" :data-clipboard-text="inviteCode" @click="copy2" :text="$t('system.copy')"></van-button>
						</div>
						<div class="item">
							<div class="item_left">
								{{ $t('system.Myinvitationlink') }}
								<div class="inp">
									<div class="link">{{ link }}</div>
								</div>
							</div>
							<van-button class="copy copyLink" :data-clipboard-text="link" @click="copy" :text="$t('system.copy')"></van-button>
						</div>
					</div>
					<div class="shareSteps">
						<div class="item">
							<div class="icon">01</div>
							<div class="txt">{{ $t('system.sendinvitationTofriends') }}</div>
						</div>
						<div class="split"><img src="../../assets/imgs/new_color/yaoqing_hengxian.png" /></div>
						<div class="item1">
							<div class="icon">02</div>
							<div class="txt">{{ $t('system.Friendscompleteregistrationandactivation') }}</div>
						</div>
						<div class="split"><img src="../../assets/imgs/new_color/yaoqing_hengxian.png" /></div>
						<div class="item">
							<div class="icon">03</div>
							<div class="txt">{{ $t('system.Getrewardsinproportion') }}</div>
						</div>
					</div>
				</div>
				<!-- 				<div class="shareReward">
					<div class="caption mainFontColor">
						<h3><strong>{{ $t('team.InviteRewards')}}</strong></h3>
						<div class="more"></div>
					</div>
				</div> -->
			</div>
		</div>
		<div class="bottom">
			<!-- <div class="btn btnL" @click="routerTo('/sharePoster')">{{ $t('system.Generateinvitationposter') }}</div> -->
			<div class="btn btnL" @click="showShareImg">{{ $t('system.Generateinvitationposter') }}</div>
			<div class="btn btnR" @click="showPoster">{{ $t('team.FaceFaceInvitation') }}</div>
		</div>
		<!-- <div v-else>
			<common-head :is-return="true"></common-head>
			<div class="shareFooter">
				<van-button @click="gotoMember" class="footerBtn">{{ $t('system.toBeVIP') }}</van-button>
			</div>
		</div> -->

		<!-- 面对面邀请-弹出 -->
		<div class="posterLayout" v-show="isShowPoster"></div>
		<div class="posterCode" v-show="isShowPoster">
			<div class="poster">
				<!-- <img src="@/assets/imgs/share/invitation_poster.png" alt=""> -->
				<div class="title">{{$t('system.FaceToFaceShare')}}</div>
				<div class="code"><div id="share_page_qrcode" style="width: 100%;height: 100%" ref="share_page_qrcode"></div></div>
			</div>
			<div class="close" @click="closePoster"></div>
		</div>

		<!-- 邀请链接+邀请码 -->
		<div style="display: none">
			<van-field v-model="link" :name="$t('team.InviteLink')" :label="$t('team.InviteLink')" :placeholder="$t('team.InviteLink')" />
			<van-field v-model="inviteCode" :name="$t('team.InviteCode')" :label="$t('team.InviteCode')" :placeholder="$t('team.InviteCode')" />
		</div>

		<!-- 邀请图片 -->
		<van-action-sheet v-model="showShare" :title="$t('system.invitefriends')" @closed="closed">
			<div class="content">
				<div class="prev" @click="prev"><</div>
				<van-swipe ref="sharePoster" indicator-color="white" @change="onChange" :show-indicators="false">
					<van-swipe-item>
						<div id="shareBox" ref="shareBox" class="shareBox">
							<div class="bg">
								<img src="@/assets/imgs/share/shareBg.png" @dragstart.prevent>
							</div>
							<div class="con">
								<div class="shareTitle">
									<img src="@/assets/imgs/share/title.png" @dragstart.prevent>
								</div>
								<div class="tableBox">
									<div class="highlight"></div>
									<div class="tr">
										<div class="sub"></div>
										<div class="hailang subTitle">{{$t('system.wave')}}</div>
										<div class="comm subTitle">{{$t('system.routine')}}</div>
									</div>
									<div class="tr">
										<div class="sub">{{$t('system.priceDB')}}</div>
										<div class="hailang">
											<img src="@/assets/imgs/share/check.png" @dragstart.prevent>
											<span>{{$t('system.priceDBWave')}}</span>
										</div>
										<div class="comm">
											<img src="@/assets/imgs/share/close.png" @dragstart.prevent>
											<span>{{$t('system.priceDBRoutine')}}</span>
										</div>
									</div>
									<div class="tr">
										<div class="sub">{{$t('system.orderDB')}}</div>
										<div class="hailang">
											<img src="@/assets/imgs/share/check.png" @dragstart.prevent>
											<span>{{$t('system.orderDBWave')}}</span>
										</div>
										<div class="comm">
											<img src="@/assets/imgs/share/close.png" @dragstart.prevent>
											<span>{{$t('system.orderDBRoutine')}}</span>
										</div>
									</div>
									<div class="tr">
										<div class="sub">{{$t('system.plungeDB')}}</div>
										<div class="hailang">
											<img src="@/assets/imgs/share/check.png" @dragstart.prevent>
											<span>{{$t('system.plungeDBWave')}}</span>
										</div>
										<div class="comm">
											<img src="@/assets/imgs/share/close.png" @dragstart.prevent>
											<span>{{$t('system.plungeDBRoutine')}}</span>
										</div>
									</div>
									<div class="tr">
										<div class="sub">{{$t('system.uncoatDB')}}</div>
										<div class="hailang">
											<img src="@/assets/imgs/share/check.png" @dragstart.prevent>
											<span>{{$t('system.uncoatDBWave')}}</span>
										</div>
										<div class="comm">
											<img src="@/assets/imgs/share/close.png" @dragstart.prevent>
											<span>{{$t('system.uncoatDBRoutine')}}</span>
										</div>
									</div>
									<div class="tr">
										<div class="sub">{{$t('system.geoDB')}}</div>
										<div class="hailang">
											<img src="@/assets/imgs/share/check.png" @dragstart.prevent>
											<span>{{$t('system.geoDBWave')}}</span>
										</div>
										<div class="comm">
											<img src="@/assets/imgs/share/close.png" @dragstart.prevent>
											<span>{{$t('system.geoDBRoutine')}}</span>
										</div>
									</div>
								</div>
							</div>
							<div class="shareBottom">
								<div class="op_container2">
									<p class="yaoQingStyle">{{$t('system.systemTitle')}}</p>
									<p class="yaoQingStyle">{{$t('team.InviteCode')}} <span>{{ inviteCode }}</span></p>
								</div>
								<div class="codeImgStyle2">
									<img :src="qrcodeimg" alt="">
								</div>
							</div>
						</div>
					</van-swipe-item>
					<van-swipe-item>
						<div id="shareBox2" ref="shareBox2" class="shareBox">
							<div class="bg">
								<img v-if="url" :src="url+ '?time=' + new Date().valueOf()" crossorigin="anonymous" @dragstart.prevent>
							</div>
							<div class="shareBottom">
								<div class="op_container2">
									<p class="yaoQingStyle">{{$t('system.systemTitle')}}</p>
									<p class="yaoQingStyle">{{$t('team.InviteCode')}} <span>{{ inviteCode }}</span></p>
								</div>
								<div class="codeImgStyle2">
									<img :src="qrcodeimg" alt="">
								</div>
							</div>
            			</div>
					</van-swipe-item>
				</van-swipe>
				<div class="next" @click="next">></div>
				<div class="btnBox">
					<div class="saveBtn" @click="saveImg">
						<img src="@/assets/imgs/share/img.png" @dragstart.prevent>
					</div>
					{{$t('system.saveImg')}}
				</div>
			</div>
		</van-action-sheet>
	</div>
</template>

<script>
import Clipboard from 'clipboard';
// 地址转换二维码插件
import QRCode from 'qrcodejs2';
import commonHeader from '@/components/commonHeader';
import html2canvas from "html2canvas";
export default {
	name: 'sharePage',
	components: { commonHeader },
	data() {
		return {
			msg: '<',
			inviteCode: '',
			link: '',
			hasCode: 1,
			// 20200410----
			isShowPoster: false,
			// 20210415-----
			showShare:false,
			posterNum:0,
			url:'',
			qrcodeimg:'',
		};
	},
	created() {
		this.getPictureList();
	},
	mounted() {
		this.getInviteCode();
	},
	methods: {
		prev(){
			this.$refs.sharePoster.prev()
		},
		next(){
			this.$refs.sharePoster.next()
		},
		getPictureList() {
			this.$post2('User/Api/Passport/getPictureList', {})
				.then(res => {
					this.url = res.invite_poster_url;
				})
				.catch(e => {
					// this.$toast.fail(e);
				});
		},
		// 保存图片
		saveImg(){
			this.$nextTick(()=>{
				var ref=this.posterNum==1?this.$refs.shareBox2:this.$refs.shareBox
				// 第一个参数是需要生成截图的元素,第二个是自己需要配置的参数,宽高等
				html2canvas(ref, {
					backgroundColor: null,
					useCORS: true,
				}).then((canvas) => {
					let dataURL = canvas.toDataURL("image/png");
					// console.log(dataURL)
					const creatDom = document.createElement('a')
					document.body.appendChild(creatDom)
					creatDom.href = dataURL
					creatDom.download = 'Fllow'
					creatDom.click()
				});
			})
		},
		closed(){
			this.$refs.sharePoster.swipeTo(0)
			this.posterNum=0;
		},
		// 海报切换
		onChange(index){
			this.posterNum=index;
		},
		// 展示邀请图片
		showShareImg(){
			this.showShare=true
		},
		onClickLeft() {
			this.$router.back(-1);
		},
		routerTo(path) {
			this.$router.push(path);
		},
		// ------20200410[start]----
		showPoster() {
			this.isShowPoster = true;
		},
		closePoster() {
			this.isShowPoster = false;
		},
		// ------20200410[end]----
		gotoMember() {
			this.$router.push({
				path: '/memberClub'
			});
		},
		// 复制
		copy() {
			var clipboard = new Clipboard('.copyLink');
			clipboard.on('success', e => {
				// this.$dialog.alert({
				// 	message: this.$t('xinzeng.fuzhichengg')
				// });
				this.$toast(this.$t('xinzeng.fuzhichengg'));
				// 释放内存
				clipboard.destroy();
			});
		},
		copy2() {
			var clipboard = new Clipboard('.copyCode');
			clipboard.on('success', e => {
				// this.$dialog.alert({
				// 	message: this.$t('xinzeng.fuzhichengg')
				// });
				this.$toast(this.$t('xinzeng.fuzhichengg'));
				// 释放内存
				clipboard.destroy();
			});
		},
		qrcode(address) {
			// console.log(address)

			new QRCode('share_page_qrcode', {
				width: 100, // 设置宽度，单位像素
				height: 100, // 设置高度，单位像素
				text: address.split('&nonce')[0] // 设置二维码内容或跳转地址
			});

			let qrcodeEle = document.getElementById("share_page_qrcode")
			let cvs = qrcodeEle.querySelector('canvas')
			this.qrcodeimg = cvs.toDataURL('image/png')
		},
		backToIndex() {
			this.$router.push('geren');
		},
		getInviteCode() {
			this.$post2('User/Api/Index/getMyInviteCode', {})
				.then(res => {
					this.inviteCode = res.refcode;
					this.link = res.link;
					// this.hasCode = 1;
					//
					// document.getElementById("qrcode").innerHTML = "";

					this.$nextTick(() => {
						this.qrcode(res.link);
					});
				})
				.catch(e => {
					// this.$toast.fail(e);
				});
		}
	}
};
</script>

<style lang="less">
#share_page_qrcode {
	img,
	canvas {
		display: block;
		width: 100%;
		height: 100%;
	}
}
</style>
<style lang="less" scoped>
// 邀请图片
/deep/ .van-action-sheet{
	max-height:100%;
}
/deep/ .van-popup--bottom {
	background: #fff;
	.van-action-sheet__content{
		border-radius:0;
	}
	.van-swipe-item{
		border-radius:0;
	}
}
.content{
	width:94%;
	margin:0 auto;
	.prev,.next{
		padding: 0.12rem;
		height:2rem;
		background: #D8D8D8;
		color: #555;
		font-size: 0.3rem;
		font-weight: 600;
		display: flex;
		justify-content: center;
		align-items: center;
		position: absolute;
		top: calc(50% - 2.2rem);
		z-index: 1;
	}
	.prev{
		left: 20px;
	}
	.next{
		right: 20px;
	}
	.shareBox{
		width:100%;
		display:flex;
		flex-direction: column;
		align-items:center;
		position:relative;
		.bg{
			font-size:0;
			img {
				width:100%;
			}
		}
		.con{
			position:absolute;
			top:0.34rem;
			left:0;
			right:0;
			.shareTitle {
				width:50%;
				margin:auto;
				img {
					width:100%;
				}
			}
			.tableBox {
				width:88%;
				margin:auto;
				font-size:0.2rem;
				margin-top:-0.28rem;
				text-align:center;
				position:relative;
				.highlight{
					position:absolute;
					left:18%;
					top:-0.2rem;
					width:41%;
					height:calc( 100% + 0.4rem );
					background: linear-gradient(135deg, #DCC297 0%, #FFE5BB 24%, #FFE6BC 56%, #C3A876 100%);
					border-radius: 8px;
				}
				img {
					width:0.24rem;
				}
				.tr{
					display:flex;
					position:relative;
					>div{
						display:flex;
						align-items:center;
						justify-content:center;
						text-align:left;
						padding:0.08rem 0.14rem;
						box-sizing:border-box;
						background:#372618;
					}
					&:first-child .sub{
						border-top-left-radius: 8px;
					}
					&:first-child .comm{
						border-top-right-radius: 8px;
					}
					&:last-child .sub{
						border-bottom-left-radius: 8px;
					}
					&:last-child .comm{
						border-bottom-right-radius: 8px;
					}
					.sub{
						width:18%;
						color:#B08F6F;
						text-align:center;
					}
					.hailang{
						width:41%;
						color:#56391E;
						background:transparent;
						justify-content:flex-start;
						&.subTitle{
							justify-content:center;
						}
						img {
							margin-right:0.12rem;
						}
					}
					.comm{
						width:41%;
						color:#fff;
						justify-content:flex-start;
						&.subTitle{
							color:#AB8A6B;
							justify-content:center;
						}
						img {
							margin-right:0.12rem;
						}
					}
				}
			}
		}
		.shareBottom{
			position:absolute;
			bottom: 0.2rem;
			left: 0;
			right: 0;
			margin: 0 0.4rem;
			display: flex;
			align-items: center;
			.op_container2 {
				flex: 1;
				text-align: left;
			}
			.yaoQingStyle {
				color: #EFBA0D;
				font-size: 12px;
				span{
					font-family: PingFangSC-Medium;
					font-weight: 500;
				}
				&:last-child {
					margin-top: 0.14rem;
				}
			}
			/deep/ .codeImgStyle2 {
				width: 1.35rem;
				height: 1.35rem;
				font-size:0;
				img {
					width: 100%;
					height: 100%;
				}
			}
		}
	}
	#shareBox2{
		width: 75.9%;
		margin: auto;
	}
	.btnBox{
		display:flex;
		flex-direction: column;
		align-items:center;
		font-size:0.28rem;
		margin-top:0.36rem;
		margin-bottom:0.36rem;
		.saveBtn{
			width:1.32rem;
			height:1.32rem;
			background: #F8F8F8;
			border-radius: 50%;
			display:flex;
			justify-content:center;
			align-items:center;
			margin-bottom:0.15rem;
			img {
				width:0.56rem;
			}
		}
	}
}

.btn1,
.btn2 {
	color: #000;
	border: 0;
	font-size: 0.28rem;
	background: none;
	padding: 0;
	margin-right: 0.36rem;
}

/*.yaoQingStyle {*/
/* position: absolute;
            bottom: 1.2rem;
            left: 0rem auto; */
/*margin-top: 11rem;*/
/*}*/

.titlePStyle {
	/* margin-bottom: 0.2rem; */
	margin-top: 0.2rem;
	/* padding-top: 0.1rem; */
	margin-right: 7rem;
	transform: rotate(180deg);
}

.qrcode {
	width: 0.3rem;
}

.fengXiangCode {
	position: absolute;
	bottom: 6.5rem;
	left: 3.2rem;
}

.spanStyle {
	margin-right: 7rem;
}

.op_container {
	flex: 1;
	text-align: left;
	margin-left: 0.5rem;
}

.title {
	height: 100%;
	background-color: #ffffff;
	&.bgVip {
		height: 100%;
		background-image: url('../../assets/imgs/share/poster.png');
	}

	.shareFooter {
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		padding: 0.4rem 0.6rem;
		display: flex;
		align-items: center;
		background: linear-gradient(180deg, #fefeff 0%, #e6e6ff 100%);

		.codeImgStyle {
			width: 1.29rem;
			height: 1.29rem;

			img {
				width: 100%;
				height: 100%;
			}
		}

		.yaoQingStyle {
			color: #000;
			font-size: 0.32rem;
			margin: 0.15rem 0 0.2rem;
		}

		.rightBtn {
			margin-right: 0.2rem;
		}
	}
}

.footerBtn {
	color: #ffffff;
	font-size: 0.58rem;
	width: 4.24rem;
	height: 1.54rem;
	padding-bottom: 0.14rem;
	border-radius: 0.77rem;
	background: #ff4b04 url(../../assets/imgs/share/VIP.png);
	background-size: 100% 100%;
	margin: 0 auto;
	span {
		font-weight: bold;
	}
}

// --------20200410[start]----------
.layoutBox {
	padding: 0;
	font-family: PingFangSC-Regular;
	position: relative;
	// top: 0.88rem;
	padding-top: 0.88rem;
	padding-top: calc(env(safe-area-inset-top) + 0.88rem);
	left: 0;
	width: 100%;
	// z-index: 9999;
	&::before {
		content: '';
		height: 5.59rem;
		height: calc(env(safe-area-inset-top) + 5.59rem);
		width: 100%;
		background: url(../../assets/imgs/new_color/yaoqing_bg.png) no-repeat center top;
		background-size: 100% 100%;
		display: inline-block;
		position: absolute;
		top: 0;
	}
	.shareAds {
		position: relative;
		z-index: 10;
		margin: 0 auto;
		margin-top: 0.64rem;
		margin-top: calc(0.64rem - env(safe-area-inset-top));
		// margin-bottom: 0.47rem;
		text-align: center;
		color: #ffffff;
		.big_txt {
			padding: 0 0.2rem;
			font-size: 0.6rem;
			padding-bottom: 0.2rem;
		}
		.small_txt {
			font-size: 0.24rem;
		}
	}
	.shareSteps {
		display: flex;
		justify-content: space-between;
		align-items: center;
		font-size: 0.88rem;
		padding: 0 0.64rem;
		margin-top: 1.07rem;
		.item {
			display: flex;
			flex-direction: column;
			align-items: center;
			.icon {
				font-size: 0.32rem;
				width: 0.89rem;
				height: 0.89rem;
				border-radius: 50%;
				background-color: #fff6c5;
				color: #efba0d;
				line-height: 0.89rem;
				text-align: center;
			}
			.txt {
				margin-top: 0.2rem;
				text-align: center;
				font-size: 0.28rem;
				width: 1.2rem;
			}
		}
		.item1 {
			display: flex;
			flex-direction: column;
			align-items: center;
			.icon {
				font-size: 0.32rem;
				width: 0.89rem;
				height: 0.89rem;
				border-radius: 50%;
				background-color: #fff6c5;
				color: #efba0d;
				line-height: 0.89rem;
				text-align: center;
			}
			.txt {
				margin-top: 0.2rem;
				text-align: center;
				font-size: 0.28rem;
				width: 1.68rem;
			}
		}
		.split {
			margin-top: -1.15rem;
			img {
				width: 0.38rem;
				height: 0.07rem;
			}
		}
	}
	.shareInfo {
		margin-top: 1.8rem;
		width: 100%;
		height: 5.24rem;
		background-image: url(../../assets/imgs/new_color/yaoqing_2.png);
		background-size: 100% 100%;
		background-repeat: no-repeat;
		position: relative;
		z-index: 88;
		.top {
			padding: 0 0.76rem;
			padding-top: 0.5rem;
		}
		.item {
			display: flex;
			justify-content: space-between;
			align-items: center;
			color: #333333;
			font-size: 0.24rem;
			.item_left {
				display: flex;
				align-items: center;
			}
			&:last-child {
				margin-bottom: 0;
			}
			.inp {
				font-family: PingFangSC-Medium;
				// width: 1.8rem;
				font-size: 0.32rem;
				color: #333;
				height: 0.7rem;
				line-height: 0.71rem;
				padding: 0 0.1rem 0 0.35rem;
				// background: #F6F7F9;
				// border-radius: .34rem;
				display: flex;
				justify-content: space-between;
				.link {
					width: 2.5rem;
					overflow: hidden;
					white-space: nowrap;
					text-overflow: ellipsis;
				}
			}
			.copy {
				width: 1.2rem;
				height: 0.41rem;
				font-size: 0.24rem;
				color: #efba0d;
				border: 1px solid #efba0d;
				border-radius: 0.325rem;
			}
		}
	}
	.shareReward {
		padding: 0.25rem;
		background: #ffffff;
		border-radius: 0.12rem;
		.caption {
			height: 0.5rem;
			display: flex;
			justify-content: space-between;
			align-items: center;
			margin-bottom: 0.15rem;
			h3 {
				font-size: 0.32rem;
			}
		}
	}
}
.posterLayout {
	position: fixed;
	z-index: 98;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	background: rgba(4, 4, 15, 0.4);
}
.posterCode {
	position: fixed;
	z-index: 99;
	left: 1.02rem;
	right: 1.02rem;
	top: 50%;
	width: 5.47rem;
	height: 6.06rem;
	-webkit-transform: translateY(-50%);
	transform: translateY(-50%);
	background-image: url(../../assets/imgs/new_color/mianduimian.png);
	background-size: 100% 100%;
	background-repeat: no-repeat;
	.title {
		font-family: PingFangSC-Regular;
		color: #333333;
		font-size: 0.32rem;
		margin: 0 auto;
		text-align: center;
		padding-top: 0.62rem;
		font-weight: bold;
	}
	.poster {
		img {
			display: block;
			width: 100%;
		}
		.code {
			margin-top: 0.58rem;
			margin-left: 1.07rem;
			width: 3.32rem;
			height: 3.36rem;
		}
	}
	.close {
		width: 0.8rem;
		height: 0.8rem;
		background: url(../../assets/imgs/share/icon_shut.png) no-repeat center center;
		background-size: 0.6rem 0.6rem;
		margin: 1.5rem auto 0;
	}
}
.bottom {
	padding: 0.25rem;
	display: flex;
	justify-content: space-between;
	.btn {
		color: #333333;
		font-size: 0.32rem;
		text-align: center;
		width: 3.26rem;
		height: 0.9rem;
		line-height: 0.9rem;
		background: #fad538;
		border-radius: 0.16rem;
	}
}
// --------20200410[start]----------
</style>
